<template>
  <section class="intro-x">
    <div class="w-full flex my-5 items-center">
      <back-button />
      <h1 class="font-bold capitalize text-xl ml-2">Carga masiva</h1>
    </div>
    <div>
      <div class="w-full grid gap-4 grid-cols-1 lg:grid-cols-2">
        <div @click="handleExcelDownload" class="text-center bg-green-500 py-6 rounded-md uppercase font-bold flex justify-center items-center text-white cr-pointer">
          <div>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
            </svg>
          </div>
          <div class="ml-2 relative" style="top:2px;">exportar plantilla </div>
        </div>
        <div @click="fileInput.click()" class="text-center bg-yellow-500 py-6 rounded-md uppercase font-bold flex justify-center items-center text-white cr-pointer">
          <div>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
            </svg>
          </div>
          <div class="ml-2 relative" style="top:2px;">Importar plantilla </div>
        </div>
        <div></div>
        <div> <messageError :text="errors.adjunto" /> </div>
        <input v-show="false"
        id="upfile"
        type="file"
        @change="onChangeFile($event.target.files[0])"
        class="form-control"
        ref="fileInput"/>
      </div>
      <div class="mt-5" v-show="getInconsisData.length">
        <p>Errores de la plantilla</p>
      </div>
      <div class="w-full mb-5" v-show="getInconsisData.length">
        <DataTable :value="getInconsisData">
          <Column header="Fila" field="field" headerStyle="width:30%;" />
          <Column header="Campo" field="column" headerStyle="width:30%;" />
          <Column header="Mensaje" field="error" />
        </DataTable>
      </div>
      <div class="mt-5">
        <p>Consideraciones</p>
      </div>
      <div class="w-full mb-5">
        <DataTable :value="getConsiderations">
          <Column header="Campo" field="campo" headerStyle="width:30%;" />
          <Column header="Descripción" field="descripcion" />
        </DataTable>
      </div>
    </div>
  </section>
</template>

<script>
import inventaryImportTemplate from '../../../../services/inventaryImportTemplate'
import { computed, onMounted, reactive, ref } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { messageSuccess } from '../../../../../../../libs/mensajes'
import { useForm, useField } from 'vee-validate'
import { object, mixed } from 'yup'
import { useRouter } from 'vue-router'

export default {
  name: 'CargaMasiva',
  components: {
    DataTable,
    Column
  },
  setup () {
    const router = useRouter()
    const fileInput = ref(null)
    const considerations = reactive({
      total: 0,
      page: 1,
      data: [
        { campo: 'id', descripcion: 'id del inventario, debe existir en la base de datos' },
        // { campo: 'Código', descripcion: 'código del producto' },
        // { campo: 'Producto', descripcion: 'Nombre del producto' },
        { campo: 'Cantidad', descripcion: 'Cantidad del producto, debe ser numérico y se tendrá en cuenta los que sean digitados' }
      ]
    })
    const inconsisData = reactive({
      total: 0,
      page: 1,
      data: []
    })
    const getConsiderations = computed(() => {
      return considerations.data
    })
    const getInconsisData = computed(() => {
      return inconsisData.data
    })
    const validationSchema = object().shape({
      adjunto: mixed().required()
        .test('fileFormat', 'Formato Excel', (value) => {
          return value && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type)
        })
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    useField('adjunto', null, { initialValue: null })
    const onChangeFile = (value) => {
      model.adjunto = value
      onSubmit()
    }
    const onSubmit = handleSubmit((values) => {
      var formData = new FormData()
      formData.append('files', model.adjunto)
      inconsisData.data = []
      inventaryImportTemplate(formData).then(({ status, data }) => {
        document.getElementById('upfile').value = ''
        handleReset()
        messageSuccess('Plantilla importada')
        router.back()
      }).catch(({ response }) => {
        const { errors } = response.data
        inconsisData.data = errors
        document.getElementById('upfile').value = ''
        handleReset()
      })
    })

    const handleExcelDownload = () => {
      window.open(`${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/inventary/export-template-xls`, '_blank')
    }
    onMounted(() => {
      setTimeout(() => {
        handleReset()
      }, 200)
    })
    return {
      model,
      errors,
      getConsiderations,
      getInconsisData,
      handleExcelDownload,
      onSubmit,
      fileInput,
      onChangeFile
    }
  }

}
</script>

<style scoped>
.cr-pointer{
  cursor: pointer;
}
</style>
